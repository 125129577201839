import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Markdown from "../components/markdown"
import PageLayout from "../components/page-layout"
import PageBreadcrumbs from "../components/page-breadcrumbs"

const Page = ({ data: { strapiPage: { fields } }, pageContext }) => {
  const { title, description, keywords, ogpImage } = fields

  // 固定ページでは投稿とは異なり MDX を許容する
  return (
    <Layout>
      <PageBreadcrumbs name={pageContext.name} />
      <Seo title={title} description={description} keywords={keywords} image={ogpImage} />
      <h1 className="display-none">{title}</h1>
      <PageLayout name={pageContext.name}>
        <Markdown>{fields.body}</Markdown>
      </PageLayout>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query PageQuery($id: String!) {
    strapiPage(id: { eq: $id }) {
      fields {
        title
        body
        description
        keywords
        ogpImage
      }
    }
  }
`
