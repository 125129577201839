/**
 * 固定ページ `support` のレイアウト
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import PostList from "../post-list"
import HeaderImage from "../header-image"
import imageMap from "../image-map"
import Col2 from "../col-2"

const PageLayout = ({ children }) => {
  const result = useStaticQuery(graphql`
    query {
      newsPosts: allStrapiPost(filter: {fields: {areas: {elemMatch: {machineName: {eq: "SN"}}}}}, sort: {fields: fields___date, order: DESC}, limit: 3) {
        nodes {
          ...PostTeaser
        }
        totalCount
      }

      tipPosts: allStrapiPost(filter: {fields: {areas: {elemMatch: {machineName: {eq: "SP"}}}}}, sort: {fields: fields___date, order: DESC}, limit: 3) {
        nodes {
          ...PostTeaser
        }
        totalCount
      }

      mainImages: allFile(filter: {relativeDirectory: {eq: "pages/support"}}) {
        nodes {
          ...MainImage
        }
      }
    }
  `)
  const { nodes: newsPosts, totalCount: newsTotalCount } = result.newsPosts
  const { nodes: tipPosts, totalCount: tipTotalCount } = result.tipPosts
  const mainImages = imageMap(result.mainImages.nodes)

  const movies = (
    <div>
      <h2 className="hidden">動画</h2>
      <img className="mb-3" src="/uploads/L1_spt_M_C3_eb6c631b36.jpg" alt="ドローン 格納庫" />
      <img className="mb-3" src="/uploads/L1_spt_M_C4_64d22115ae.jpg" alt="ドローン メンテナンス" />
    </div>
  )

  return (
    <div>
      <HeaderImage
        pcImage={{
          src: mainImages['L1_spt_P1'],
	  alt: "入念なメンテナンス",
        }}
        spImages={[
          {
            src: mainImages['L1_spt_S1'],
	    alt: "東海地区最大級の整備設備",
          },
          {
            src: mainImages['L1_spt_S2'],
	    alt: "東海地区最大級の整備設備",
          },
        ]}
      />
      <section>
        <PostList heading="新着情報" posts={newsPosts} totalCount={newsTotalCount} morePath="/SN/" />
      </section>
      <section>
        {children}
      </section>
      <section>
        {tipPosts.length > 0 ?
          <Col2
            left={
              <section>
                <PostList heading="サポートだより" posts={tipPosts} totalCount={tipTotalCount} morePath="/SP/" excerptType="short" />
              </section>
            }
            right={movies}
          /> : ``
        }
      </section>
    </div>
  )
}

export default PageLayout
