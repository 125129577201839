/**
 * 固定ページ `spray` のレイアウト
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import PostList from "../post-list"
import HeaderImage from "../header-image"
import imageMap from "../image-map"

const PageLayout = ({ children }) => {
  const result = useStaticQuery(graphql`
    query {
      allStrapiPost(filter: {fields: {areas: {elemMatch: {machineName: {eq: "OW"}}}}}, sort: {fields: fields___date, order: DESC}, limit: 3) {
        nodes {
          ...PostTeaser
        }
        totalCount
      }

      mainImages: allFile(filter: {relativeDirectory: {eq: "pages/spray"}}) {
        nodes {
          ...MainImage
        }
      }
    }
  `)
  const { nodes: posts, totalCount } = result.allStrapiPost
  const mainImages = imageMap(result.mainImages.nodes)

  return (
    <div>
      <HeaderImage
        pcImage={{
          src: mainImages['L1_spr_P1'],
	  alt: "水稲・麦・大豆の病害虫防除・肥料・除草剤散布、芝生の成長調整剤散布など多数の場面でご活用いただけます",
        }}
        spImages={[
          {
            src: mainImages['L1_spr_S1'],
	    alt: "水稲・麦・大豆の病害虫防除・肥料・除草剤散布、芝生の成長調整剤散布など多数の場面でご活用いただけます",
          },
          {
            src: mainImages['L1_spr_S2'],
	    alt: "水稲・麦・大豆の病害虫防除・肥料・除草剤散布、芝生の成長調整剤散布など多数の場面でご活用いただけます",
          },
        ]}
      />
      <section>{children}</section>
      <section>
        <PostList heading="私たちの歩み" posts={posts} totalCount={totalCount} morePath="/OW/" />
      </section>
    </div>
  )
}

export default PageLayout
