/**
 * 固定ページ `products` のレイアウト
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import PostList from "../post-list"
import HeaderImage from "../header-image"
import imageMap from "../image-map"

const PageLayout = ({ children }) => {
  const result = useStaticQuery(graphql`
    query {
      allStrapiPost(filter: {fields: {areas: {elemMatch: {machineName: {eq: "PD"}}}}}, sort: {fields: fields___date, order: DESC}, limit: 3) {
        nodes {
          ...PostTeaser
        }
        totalCount
      }

      mainImages: allFile(filter: {relativeDirectory: {eq: "pages/products"}}) {
        nodes {
          ...MainImage
        }
      }
    }
  `)
  const { nodes: posts, totalCount } = result.allStrapiPost
  const mainImages = imageMap(result.mainImages.nodes)

  return (
    <div>
      <HeaderImage
        pcImage={{
          src: mainImages['L1_prd_P1'],
	  alt: "産業用無人航空機 農業用ドローン 無人ヘリコプター",
        }}
        spImages={[
          {
            src: mainImages['L1_prd_S1'],
	    alt: "軽量でコンパクト、持ち運びに非常に便利な農業用ドローン",
          },
          {
            src: mainImages['L1_prd_S2'],
	    alt: "「高い作業効率」と「優れた防除効果」を実現する産業用無人ヘリコプター",
          },
        ]}
      />
      <section>
        <PostList heading="新着情報" posts={posts} totalCount={totalCount} morePath="/PD/" />
      </section>
      <section>
        {children}
      </section>
    </div>
  )
}

export default PageLayout
