/**
 * 固定ページのパンくずリスト
 */

import React from "react"
import PropTypes from "prop-types"

import Breadcrumbs from "./breadcrumbs"
import pageMap from "../utils/page-map"

const PageBreadcrumbs = ({ name }) => {
  let parents = []
  let leaf = ``
  try {
    leaf = pageMap(name).label
  } catch (e) {
  }

  switch (name) {
    case 'products-drone':
      parents = [pageMap(`products`)]
      break
    case 'products-drone-dji':
      parents = [pageMap(`products`), pageMap(`products-drone`)]
      break
    case 'products-drone-yamaha':
      parents = [pageMap(`products`), pageMap(`products-drone`)]
      break
    case 'products-helicopter':
      parents = [pageMap(`products`)]
      break
    default:
      break
  }

  return (<Breadcrumbs parents={parents} leaf={leaf} />)
}

PageBreadcrumbs.propTypes = {
  name: PropTypes.string.isRequired,
}

export default PageBreadcrumbs
