/**
 * 固定ページ `products-drone` のレイアウト
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import PostList from "../post-list"
import HeaderImage from "../header-image"
import imageMap from "../image-map"

const PageLayout = ({ children }) => {
  const result = useStaticQuery(graphql`
    query {
      allStrapiPost(filter: {fields: {areas: {elemMatch: {machineName: {eq: "DR"}}}}}, sort: {fields: fields___date, order: DESC}, limit: 3) {
        nodes {
          ...PostTeaser
        }
        totalCount
      }

      mainImages: allFile(filter: {relativeDirectory: {eq: "pages/products-drone"}}) {
        nodes {
          ...MainImage
        }
      }
    }
  `)
  const { nodes: posts, totalCount } = result.allStrapiPost
  const mainImages = imageMap(result.mainImages.nodes)

  return (
    <div>
      <HeaderImage
        pcImage={{
          src: mainImages['L2_drn_P1'],
	  alt: "コンパクトな農業用ドローンの新たな基準を確立する DJI Agras T25",
        }}
        spImages={[
          {
            src: mainImages['L2_drn_S1'],
	    alt: "コンパクトな農業用ドローンの新たな基準を確立する DJI Agras T25",
          },
          {
            src: mainImages['L2_drn_S2'],
	    alt: "ヤマハの設計思想を実装する YMR-08",
          },
        ]}
      />
      <section>
        <PostList heading="新着情報" posts={posts} totalCount={totalCount} morePath="/DR/" />
      </section>
      <section>
        {children}
      </section>
    </div>
  )
}

export default PageLayout
