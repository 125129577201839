/**
 * SP とその他で表示画像が異なるヘッダー画像
 */

import React from "react"
import PropTypes from "prop-types"

import useDevice from "../hooks/use-device"

const SwitchedHeaderImage = ({ imageLarge, imageSmall }) => {
  const device = useDevice()

  // 初期表示時に画面幅に合わないコンポーネントが一瞬表示されてしまうのを防ぐ
  if (device === null) {
    return ``
  }

  if (device === `sp`) {
    return (
      <section className="l-pc_tablet-flex-row">
        <div className="l-pc_tablet-w-100 w-100">
          <img className="display-block w-100 h-100"
            src={imageSmall.src} alt={imageSmall.alt} />
        </div>
      </section>
    )
  }

  return (
    <section className="l-pc_tablet-flex-row">
      <div className="l-pc_tablet-w-100 w-100">
        <img className="display-block w-100 h-100"
          src={imageLarge.src} alt={imageLarge.alt} />
      </div>
    </section>
  )
}

SwitchedHeaderImage.propTypes = {
  imageLarge: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  imageSmall: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
}

export default SwitchedHeaderImage
