/**
 * 固定ページ `school` のレイアウト
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import PostList from "../post-list"
import HeaderImage from "../header-image"
import imageMap from "../image-map"

const PageLayout = ({ children }) => {
  const result = useStaticQuery(graphql`
    query {
      allStrapiPost(filter: {fields: {areas: {elemMatch: {machineName: {eq: "SL"}}}}}, sort: {fields: fields___date, order: DESC}, limit: 3) {
        nodes {
          ...PostTeaser
        }
        totalCount
      }

      mainImages: allFile(filter: {relativeDirectory: {eq: "pages/school"}}) {
        nodes {
          ...MainImage
        }
      }
    }
  `)
  const { nodes: posts, totalCount } = result.allStrapiPost
  const mainImages = imageMap(result.mainImages.nodes)

  return (
    <div>
      <HeaderImage
        pcImage={{
          src: mainImages['L1_scl_P1'],
	  alt: "東海地区最大級の教習施設",
        }}
        spImages={[
          {
            src: mainImages['L1_scl_S1'],
	    alt: "少人数制・短期間で高度な操作技術と安全知識の習得をサポート",
          },
          {
            src: mainImages['L1_scl_S2'],
	    alt: "東海地区最大級の教習施設",
          },
        ]}
      />
      <section>
        <PostList heading="新着情報" posts={posts} totalCount={totalCount} morePath="/SL/" />
      </section>
      <section>
        {children}
      </section>
    </div>
  )
}

export default PageLayout
