/**
 * 固定ページのメイン上部エリア
 */

import React from "react"
import PropTypes from "prop-types"

import PageLayoutProducts from "./products"
import PageLayoutProductsDrone from "./products-drone"
import PageLayoutProductsDroneDJI from "./products-drone-dji"
import PageLayoutProductsDroneYAMAHA from "./products-drone-yamaha"
import PageLayoutProductsHelicopter from "./products-helicopter"
import PageLayoutSchool from "./school"
import PageLayoutSpray from "./spray"
import PageLayoutSupport from "./support"
import PageLayoutCasestudy from "./casestudy"
import PageLayoutCompany from "./company"

const PageLayout = ({ name, children }) => {
  switch (name) {
    case 'products':
      return <PageLayoutProducts>{children}</PageLayoutProducts>
    case 'products-drone':
      return <PageLayoutProductsDrone>{children}</PageLayoutProductsDrone>
    case 'products-drone-dji':
      return <PageLayoutProductsDroneDJI>{children}</PageLayoutProductsDroneDJI>
    case 'products-drone-yamaha':
      return <PageLayoutProductsDroneYAMAHA>{children}</PageLayoutProductsDroneYAMAHA>
    case 'products-helicopter':
      return <PageLayoutProductsHelicopter>{children}</PageLayoutProductsHelicopter>
    case 'school':
      return <PageLayoutSchool>{children}</PageLayoutSchool>
    case 'spray':
      return <PageLayoutSpray>{children}</PageLayoutSpray>
    case 'support':
      return <PageLayoutSupport>{children}</PageLayoutSupport>
    case 'casestudy':
      return <PageLayoutCasestudy>{children}</PageLayoutCasestudy>
    case 'company':
      return <PageLayoutCompany>{children}</PageLayoutCompany>
    default:
      return children
  }
}

PageLayout.propTypes = {
  name: PropTypes.string.isRequired,
}

export default PageLayout
