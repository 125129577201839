/**
 * 固定ページ `company` のレイアウト
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SwitchedHeaderImage from "../switched-header-image"
import imageMap from "../image-map"

const PageLayout = ({ children }) => {
  const result = useStaticQuery(graphql`
    query {
      mainImages: allFile(filter: {relativeDirectory: {eq: "pages/company"}}) {
        nodes {
          ...MainImage
        }
      }
    }
  `)
  const mainImages = imageMap(result.mainImages.nodes)

  return (
    <div>
      <SwitchedHeaderImage
        imageLarge={{
          src: mainImages['L1_cmp_P1'],
          alt: "東海スカイテック 本社社屋",
        }}
        imageSmall={{
          src: mainImages['L1_cmp_S1'],
	  alt: "東海スカイテック 本社社屋",
        }}
      />
      <section>
        {children}
      </section>
    </div>
  )
}

export default PageLayout
